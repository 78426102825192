
.alert {
    font-weight: bold;
    border-radius: 0.4em;
    padding: 0.5em 1.2em;
}

.slimAlert {
    font-weight: bold;
}

.alertError {
    color: #D60E0E;
    /* background: #FFA3AE; */
}

.alertSuccess {
    color: #00B828;
    /* background: #98FFAF; */
}

.alertWarning {
    color: #FFB800;
}
