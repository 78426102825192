.login {
    padding: 2.5em;
    display: flex;
    flex-direction: column;
}

.loginPosition {
    margin: auto;
    max-width: 25em;
    width: 100%;
}

.title {
    font-weight: bold;
    color: #2196f3;
    padding-bottom: 0;
    margin-bottom: 0;
}

.clarification {
    padding-top: 0.1em;
}

.login > .loginButton {
    font-size: 1.2em;
    margin: 2em auto 0 auto;
}

.loginInput {
    padding-top: 2em;
}
