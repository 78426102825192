@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: #727272;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loginContainer {
  display: flex;
  width: 100vw;
  height: 100vh;
}

/* .App * {
  outline: 1px solid red;
} */
@-webkit-keyframes floatBubble {
  from {
      top: -30%;
      -webkit-animation-timing-function: ease-out;
  }
  to {
      top: 90%;
      -webkit-animation-timing-function: ease-out;
  }
}
@keyframes floatBubble {
  from {
      top: -30%;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
  }
  to {
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      top: 90%;
  }
}
@-webkit-keyframes spin { 
  100% {
     -webkit-transform: rotate(360deg);
  }
}
@keyframes spin { 
  100% {
     -webkit-transform: rotate(360deg);
     transform:rotate(360deg); 
  } 
}
.Login_login__3e7jP {
    padding: 2.5em;
    display: flex;
    flex-direction: column;
}

.Login_loginPosition__3D0_V {
    margin: auto;
    max-width: 25em;
    width: 100%;
}

.Login_title__2rD2n {
    font-weight: bold;
    color: #2196f3;
    padding-bottom: 0;
    margin-bottom: 0;
}

.Login_clarification__12vyh {
    padding-top: 0.1em;
}

.Login_login__3e7jP > .Login_loginButton__vxV6w {
    font-size: 1.2em;
    margin: 2em auto 0 auto;
}

.Login_loginInput__9JBlV {
    padding-top: 2em;
}


.Alert_alert__1lwVs {
    font-weight: bold;
    border-radius: 0.4em;
    padding: 0.5em 1.2em;
}

.Alert_slimAlert__3fa3u {
    font-weight: bold;
}

.Alert_alertError__tiJt7 {
    color: #D60E0E;
    /* background: #FFA3AE; */
}

.Alert_alertSuccess__17VbB {
    color: #00B828;
    /* background: #98FFAF; */
}

.Alert_alertWarning__16ZxK {
    color: #FFB800;
}

