body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: #727272;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');