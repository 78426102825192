.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginContainer {
  display: flex;
  width: 100vw;
  height: 100vh;
}

/* .App * {
  outline: 1px solid red;
} */
@-webkit-keyframes floatBubble {
  from {
      top: -30%;
      -webkit-animation-timing-function: ease-out;
  }
  to {
      top: 90%;
      -webkit-animation-timing-function: ease-out;
  }
}
@keyframes floatBubble {
  from {
      top: -30%;
      animation-timing-function: ease-out;
  }
  to {
      animation-timing-function: ease-out;
      top: 90%;
  }
}

@-moz-keyframes spin { 
  100% { 
    -moz-transform: rotate(360deg); 
  } 
}
@-webkit-keyframes spin { 
  100% {
     -webkit-transform: rotate(360deg);
  }
}
@keyframes spin { 
  100% {
     -webkit-transform: rotate(360deg);
     transform:rotate(360deg); 
  } 
}